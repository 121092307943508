.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}

.App-header {
  color: #fff;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary-color: #000;
  --secondary-color: #86868b;
  --accent-color: #0071e3;
  --background-color: #fff;
  --section-background: #f5f5f7;
  --border-color: #d2d2d7;
  --text-color: #1d1d1f;
  --spacing-unit: 8px;
}

.home-page {
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 8px;
  flex-direction: column;
  max-width: 1000px;
  min-height: 100vh;
  margin: 0 auto;
  font-family: SF Pro Display, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  line-height: 1.5;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 30px #0000001a;
}

.container {
  padding: 0 calc(var(--spacing-unit) * 3);
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.home-header {
  -webkit-backdrop-filter: blur(20px);
  padding: calc(var(--spacing-unit) * 3) 0;
  background-color: #fffc;
  border-bottom: 1px solid #0000001a;
}

.home-header-content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.home-logo {
  letter-spacing: -.02em;
  font-size: 32px;
  font-weight: 600;
}

.home-nav {
  gap: calc(var(--spacing-unit) * 3);
  display: flex;
}

.home-nav a {
  color: var(--text-color);
  padding: calc(var(--spacing-unit)) calc(var(--spacing-unit) * 2);
  background-color: var(--section-background);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  font-size: 16px;
  text-decoration: none;
  transition: color .3s;
}

.home-nav a:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.home-main {
  padding: calc(var(--spacing-unit) * 10) 0;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  flex: 1;
}

.home-hero {
  max-width: 700px;
  margin: 0 auto;
}

.home-hero h1 {
  margin-bottom: calc(var(--spacing-unit) * 3);
  letter-spacing: -.02em;
  font-size: 48px;
  line-height: 1.1;
}

.home-hero h2 {
  color: var(--secondary-color);
  margin-bottom: calc(var(--spacing-unit) * 6);
  font-size: 24px;
  font-weight: 400;
}

.home-features {
  justify-content: center;
  gap: calc(var(--spacing-unit) * 4);
  margin-top: calc(var(--spacing-unit) * 8);
  display: flex;
}

.home-feature {
  padding: calc(var(--spacing-unit) * 4);
  text-align: left;
  background-color: #fffc;
  border-radius: 12px;
  max-width: 300px;
  box-shadow: 0 4px 20px #0000000d;
}

.home-feature h3 {
  margin-bottom: calc(var(--spacing-unit) * 2);
  font-size: 20px;
}

.home-feature p {
  color: var(--secondary-color);
  font-size: 16px;
}

.home-footer {
  background-color: var(--primary-color);
  color: #fff;
  padding: calc(var(--spacing-unit) * 4) 0;
  text-align: center;
}

.home-footer p {
  margin-bottom: calc(var(--spacing-unit));
  font-size: 14px;
}

.home-footer a {
  color: #fff;
  text-decoration: none;
}

.home-footer a:hover {
  text-decoration: underline;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.home-hero, .home-feature {
  animation: .8s ease-out forwards fadeIn;
}

.home-feature:nth-child(2) {
  animation-delay: .2s;
}

.home-feature:nth-child(3) {
  animation-delay: .4s;
}

@media (max-width: 768px) {
  .home-header-content {
    gap: calc(var(--spacing-unit) * 2);
    flex-direction: column;
  }

  .home-features {
    flex-direction: column;
    align-items: center;
  }

  .home-hero h1 {
    font-size: 36px;
  }

  .home-hero h2 {
    font-size: 20px;
  }
}

:root {
  --primary-color: #0071e3;
  --secondary-color: #333;
  --background-color: #f5f5f7;
  --text-color: #333;
  --light-text: #86868b;
  --border-color: #d2d2d7;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.about-page {
  padding: var(--spacing-lg);
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: #fff;
  border-radius: 12px;
  flex-direction: column;
  max-width: 1000px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  box-shadow: 0 4px 20px #0000001a;
}

.about-header {
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--border-color);
  margin-bottom: var(--spacing-lg);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.about-logo {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 600;
}

.about-nav {
  gap: var(--spacing-md);
  display: flex;
}

.about-nav a {
  color: var(--text-color);
  font-weight: 500;
  text-decoration: none;
  transition: color .2s;
}

.about-nav a:hover {
  color: var(--primary-color);
}

.about-hero {
  text-align: center;
  padding: var(--spacing-xl) 0;
  margin-bottom: var(--spacing-xl);
  background: linear-gradient(135deg, #f5f5f7 0%, #e5e5ea 100%);
  border-radius: 8px;
}

.about-hero h1 {
  margin-bottom: var(--spacing-md);
  font-size: 48px;
  font-weight: 700;
}

.about-hero p {
  color: var(--light-text);
  max-width: 600px;
  margin: 0 auto;
  font-size: 20px;
}

.about-content {
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  flex-direction: column;
  display: flex;
}

.about-section {
  margin-bottom: var(--spacing-lg);
}

.about-section h2 {
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-sm);
  font-size: 28px;
  position: relative;
}

.about-section h2:after {
  content: "";
  background-color: var(--primary-color);
  width: 60px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.about-section p {
  margin-bottom: var(--spacing-md);
  line-height: 1.6;
}

.about-team {
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  display: grid;
}

.team-member {
  text-align: center;
}

.team-member img {
  object-fit: cover;
  margin-bottom: var(--spacing-md);
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.team-member h3 {
  margin-bottom: var(--spacing-sm);
}

.team-member p {
  color: var(--light-text);
  font-size: 14px;
}

.about-footer {
  padding-top: var(--spacing-lg);
  border-top: 1px solid var(--border-color);
  text-align: center;
  color: var(--light-text);
  margin-top: auto;
  font-size: 14px;
}

.about-footer a {
  color: var(--primary-color);
  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-hero, .about-section {
  animation: .8s ease-out forwards fadeIn;
}

.about-section:nth-child(2) {
  animation-delay: .2s;
}

.about-section:nth-child(3) {
  animation-delay: .4s;
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 36px;
  }

  .about-hero p {
    font-size: 18px;
  }

  .about-team {
    grid-template-columns: 1fr;
  }
}

:root {
  --primary-color: #000;
  --secondary-color: #86868b;
  --accent-color: #0071e3;
  --background-color: #fff;
  --section-background: #f5f5f7;
  --border-color: #d2d2d7;
  --text-color: #1d1d1f;
  --spacing-unit: 8px;
  --secondary-font-size: 12px;
  --detail-font-size: 13px;
  --section-spacing: 32px;
  --item-spacing: 24px;
}

.resume-page {
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .01em;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
  font-family: SF Pro Display, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  overflow: hidden;
  box-shadow: 0 0 30px #0000001a;
}

.container {
  padding: 0 calc(var(--spacing-unit) * 3);
  text-align: left;
  max-width: 700px;
  margin: 0 auto;
}

.resume-page h1, .resume-page h2, .resume-page h3, .resume-page h4, .resume-page h5, .resume-page h6 {
  letter-spacing: -.02em;
  text-align: left;
  color: var(--text-color);
  margin-top: 0;
  font-weight: 600;
  line-height: 1.3;
}

.resume-page h1 {
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  font-size: 32px;
}

.resume-page h2 {
  margin-bottom: calc(var(--spacing-unit) * 2);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: calc(var(--spacing-unit) * .75);
  font-size: 20px;
}

.resume-page h3 {
  margin-bottom: calc(var(--spacing-unit) * .75);
  font-size: 16px;
}

.resume-page h4 {
  margin-bottom: calc(var(--spacing-unit) * 1);
  font-size: 14px;
  font-weight: 500;
}

.resume-page p {
  margin-bottom: calc(var(--spacing-unit) * 1);
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
}

.resume-page a {
  color: var(--accent-color);
  text-decoration: none;
  transition: color .3s;
}

.resume-page a:hover {
  text-decoration: underline;
}

.resume-page ul {
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  text-align: left;
  padding-left: 20px;
  list-style-position: outside;
}

.resume-page li {
  margin-bottom: calc(var(--spacing-unit) * .75);
  text-align: left;
  font-size: var(--detail-font-size);
  line-height: 1.5;
}

.resume-header {
  -webkit-backdrop-filter: blur(20px);
  z-index: 1000;
  background-color: #fffc;
  border-bottom: 1px solid #0000001a;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.resume-header nav {
  padding: 0 calc(var(--spacing-unit) * 3);
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  height: 44px;
  margin: 0 auto;
  display: flex;
}

.resume-header .logo {
  font-size: 16px;
  font-weight: 600;
}

.resume-header nav ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.resume-header nav li {
  margin: 0 calc(var(--spacing-unit) * 2);
  margin-bottom: 0;
}

.resume-header nav a {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 400;
  transition: color .3s;
}

.resume-header nav a:hover {
  color: var(--accent-color);
  text-decoration: none;
}

.resume-page .hero {
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  justify-content: center;
  align-items: center;
  height: 25vh;
  min-height: 180px;
  padding-top: 44px;
  display: flex;
}

.resume-page .hero h1, .resume-page .hero h2 {
  text-align: center;
}

.resume-page .hero h2 {
  color: var(--secondary-color);
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  border-bottom: none;
  font-size: 18px;
  font-weight: 400;
}

.resume-page .hero p {
  color: var(--secondary-color);
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  font-size: 15px;
}

.resume-page section {
  padding: var(--section-spacing) 0;
  border-bottom: 1px solid var(--border-color);
  text-align: left;
}

.resume-page section:last-of-type {
  border-bottom: none;
}

.resume-page section:nth-child(2n) {
  background-color: var(--section-background);
}

.about-content {
  gap: calc(var(--spacing-unit) * 1.5);
  text-align: left;
  flex-direction: column;
  display: flex;
}

.about-text {
  flex: 1;
}

.about-text p {
  font-size: 14px;
  line-height: 1.5;
}

.skill-tags {
  gap: calc(var(--spacing-unit) * .6);
  margin-bottom: calc(var(--spacing-unit) * 1.25);
  text-align: left;
  flex-wrap: wrap;
  display: flex;
}

.skill-tags span {
  background-color: var(--section-background);
  padding: calc(var(--spacing-unit) * .3) calc(var(--spacing-unit) * 1);
  border: 1px solid var(--border-color);
  border-radius: 14px;
  font-size: 11px;
  display: inline-block;
}

.experience-item, .education-item, .project-item {
  margin-bottom: var(--item-spacing);
  padding-bottom: var(--item-spacing);
  border-bottom: 1px solid var(--border-color);
  text-align: left;
}

.experience-item:last-child, .education-item:last-child, .project-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.experience-header, .education-header, .project-header {
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  text-align: left;
}

.company-info-row {
  margin-bottom: calc(var(--spacing-unit) * .75);
  position: relative;
}

.company {
  color: var(--secondary-color);
  font-size: var(--secondary-font-size);
  text-align: left;
  display: inline-block;
}

.period-location {
  font-size: var(--secondary-font-size);
  color: var(--secondary-color);
  white-space: nowrap;
  float: right;
  padding-left: calc(var(--spacing-unit) * 2);
  text-align: right;
  margin-left: auto;
}

.period-location .period, .period-location .location {
  margin-bottom: 0;
  display: inline;
}

.education-info-row {
  margin-bottom: calc(var(--spacing-unit) * .4);
  position: relative;
}

.degree-info {
  text-align: left;
  width: 100%;
}

.degree-info p {
  margin-bottom: calc(var(--spacing-unit) * .4);
  position: relative;
}

.experience-details, .education-details, .project-details {
  margin-top: calc(var(--spacing-unit) * 1.5);
  text-align: left;
}

.project-tech strong {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
}

.experience-details {
  margin-top: calc(var(--spacing-unit) * 1.5);
}

.experience-details li {
  font-size: var(--detail-font-size);
  margin-bottom: calc(var(--spacing-unit) * .75);
  line-height: 1.5;
}

.education-details {
  margin-top: calc(var(--spacing-unit) * 1.5);
}

.education-details p {
  font-size: var(--detail-font-size);
  margin-bottom: calc(var(--spacing-unit) * .5);
  line-height: 1.5;
}

.contact {
  background-color: var(--section-background);
  padding: calc(var(--spacing-unit) * 2.5) 0;
  text-align: left;
}

.contact p {
  margin-bottom: calc(var(--spacing-unit) * .6);
  font-size: 13px;
}

.resume-page footer {
  background-color: var(--primary-color);
  color: #fff;
  padding: calc(var(--spacing-unit) * 1.5) 0;
  text-align: center;
  font-size: 11px;
}

.resume-page footer a {
  color: #fff;
}

.resume-page footer p {
  margin-bottom: calc(var(--spacing-unit) * .4);
  text-align: center;
  font-size: 11px;
}

@media (max-width: 768px) {
  .resume-page h1 {
    font-size: 26px;
  }

  .resume-page h2 {
    font-size: 18px;
  }

  .resume-page h3 {
    font-size: 15px;
  }

  .resume-page .hero {
    padding: calc(var(--spacing-unit) * 10) 0 calc(var(--spacing-unit) * 5);
    height: auto;
  }

  .resume-header nav ul {
    display: none;
  }

  .period-location-inline, .period-location {
    float: none;
    margin-left: 0;
    margin-top: calc(var(--spacing-unit) * .4);
    padding-left: 0;
    display: block;
  }

  .degree, .company {
    margin-right: 0;
    display: block;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.resume-page section {
  animation: .8s ease-out forwards fadeIn;
}

.resume-page section:nth-child(2) {
  animation-delay: .1s;
}

.resume-page section:nth-child(3) {
  animation-delay: .2s;
}

.resume-page section:nth-child(4) {
  animation-delay: .3s;
}

.resume-page section:nth-child(5) {
  animation-delay: .4s;
}

.resume-page section:nth-child(6) {
  animation-delay: .5s;
}

.resume-header nav a.active {
  color: var(--accent-color);
}

.fade-element {
  opacity: 0;
  transition: opacity .6s ease-out, transform .6s ease-out;
  transform: translateY(20px);
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

@media print {
  .resume-page {
    box-shadow: none;
    max-width: 100%;
    font-size: 10pt;
  }

  .resume-header {
    margin-bottom: 12px;
    position: static;
  }

  .resume-page section {
    page-break-inside: avoid;
    padding: 6px 0;
  }

  .resume-page .hero {
    height: auto;
    padding: 12px 0;
  }

  .resume-page h1 {
    font-size: 16pt;
  }

  .resume-page h2 {
    font-size: 13pt;
  }

  .resume-page h3 {
    font-size: 11pt;
  }

  .resume-page p, .resume-page li {
    font-size: 9pt;
  }
}

.period, .location, .degree, .honors, .minor, .client, .company {
  color: var(--secondary-color);
  margin-bottom: calc(var(--spacing-unit) * .2);
  font-size: var(--secondary-font-size);
  font-weight: normal;
}

.period, .location {
  font-style: italic;
}

.degree, .company {
  font-weight: 500;
}

.period-location-inline {
  font-size: var(--secondary-font-size);
  color: var(--secondary-color);
  white-space: nowrap;
  float: right;
  padding-left: calc(var(--spacing-unit) * 2);
  margin-left: auto;
}

.period-location-inline .period, .period-location-inline .location {
  margin-bottom: 0;
  font-style: italic;
}

.degree {
  margin-right: calc(var(--spacing-unit) * 1);
  display: inline-block;
}

.project-item {
  margin-bottom: var(--item-spacing);
  padding-bottom: var(--item-spacing);
  border-bottom: 1px solid var(--border-color);
  text-align: left;
}

.project-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.project-header {
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  text-align: left;
}

.project-header h3 {
  margin-bottom: calc(var(--spacing-unit) * .75);
  font-size: 16px;
}

.project-header .company-info-row {
  margin-bottom: calc(var(--spacing-unit) * .75);
  position: relative;
}

.project-header .company-info-row p {
  margin-bottom: 0;
}

.project-header .company-info-row .company {
  color: var(--secondary-color);
  font-size: var(--secondary-font-size);
  text-align: left;
  display: inline-block;
}

.project-header .period-location {
  font-size: var(--secondary-font-size);
  color: var(--secondary-color);
  white-space: nowrap;
  float: right;
  padding-left: calc(var(--spacing-unit) * 2);
  text-align: right;
  margin-left: auto;
}

.project-header .period-location .period, .project-header .period-location .location {
  margin-bottom: 0;
  display: inline;
}

.project-tech {
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  text-align: left;
}

.project-tech p {
  margin-bottom: calc(var(--spacing-unit) * .5);
  font-size: var(--secondary-font-size);
  color: var(--secondary-color);
  line-height: 1.5;
}

.project-tech strong {
  color: var(--text-color);
  font-weight: 500;
}

.project-details {
  margin-top: calc(var(--spacing-unit) * 1.5);
  text-align: left;
}

.project-details h4 {
  margin-bottom: calc(var(--spacing-unit) * 1);
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
}

.project-details p {
  font-size: var(--detail-font-size);
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  line-height: 1.5;
}

.project-details ul {
  margin-top: calc(var(--spacing-unit) * .75);
  margin-bottom: calc(var(--spacing-unit) * 1.5);
}

.project-details li {
  font-size: var(--detail-font-size);
  margin-bottom: calc(var(--spacing-unit) * .75);
  line-height: 1.5;
}

.language-toggle {
  color: #333;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  transition: all .3s;
}

.language-toggle:hover {
  background-color: #f0f0f0;
  border-color: #999;
}

.language-toggle, .download-button {
  justify-content: center;
  align-items: center;
  height: 30px;
  display: inline-flex;
}

@media (max-width: 768px) {
  .resume-header nav {
    flex-direction: column;
    align-items: center;
  }

  .resume-header nav ul {
    margin: 10px 0;
  }

  .nav-buttons {
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
    display: flex;
  }

  .language-toggle, .download-button {
    margin: 0 5px;
  }
}

.nav-buttons {
  align-items: center;
  display: flex;
}

.download-button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  transition: all .3s;
}

.download-button:hover {
  background-color: #0056b3;
}

#pdf-document {
  color: #333 !important;
  box-sizing: border-box !important;
  background-color: #fff !important;
  width: 210mm !important;
  height: 297mm !important;
  margin: 0 auto !important;
  padding: 12mm 15mm !important;
  font-family: Arial, sans-serif !important;
  line-height: 1.2 !important;
}

#pdf-document * {
  background-color: #0000 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-family: Arial, sans-serif !important;
}

#pdf-document .pdf-header {
  text-align: center !important;
  margin-bottom: 10px !important;
}

#pdf-document .pdf-header-row {
  justify-content: space-between !important;
  align-items: flex-start !important;
  width: 100% !important;
  display: flex !important;
}

#pdf-document .pdf-name {
  text-align: left !important;
  flex: 1 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

#pdf-document .pdf-contact-info {
  text-align: right !important;
  flex: 1 !important;
}

#pdf-document .pdf-contact-item {
  text-align: right !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 11px !important;
  line-height: 1.3 !important;
}

#pdf-document .pdf-divider {
  box-shadow: none !important;
  background-color: #999 !important;
  border: none !important;
  height: 1px !important;
  margin: 8px 0 !important;
}

#pdf-document .pdf-section {
  margin-bottom: 12px !important;
}

#pdf-document .pdf-section-title {
  color: #333 !important;
  text-transform: uppercase !important;
  letter-spacing: .5px !important;
  box-shadow: none !important;
  border-bottom: none !important;
  margin: 0 0 8px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

#pdf-document .pdf-education-entry, #pdf-document .pdf-experience-entry, #pdf-document .pdf-project-entry {
  margin-bottom: 10px !important;
}

#pdf-document .pdf-entry-header {
  justify-content: space-between !important;
  align-items: flex-start !important;
  margin-bottom: 4px !important;
  display: flex !important;
}

#pdf-document .pdf-institution, #pdf-document .pdf-job-title, #pdf-document .pdf-project-title {
  margin: 0 !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

#pdf-document .pdf-entry-date {
  text-align: right !important;
}

#pdf-document .pdf-date {
  white-space: nowrap !important;
  font-size: 11px !important;
  font-style: italic !important;
}

#pdf-document .pdf-entry-details {
  margin: 0 !important;
}

#pdf-document .pdf-degree, #pdf-document .pdf-minor, #pdf-document .pdf-honors, #pdf-document .pdf-tech-stack {
  margin-bottom: 3px !important;
  font-size: 11px !important;
}

#pdf-document .pdf-experience-details, #pdf-document .pdf-project-details {
  margin: 4px 0 0 !important;
  padding-left: 18px !important;
}

#pdf-document .pdf-detail-item {
  margin-bottom: 4px !important;
  padding-bottom: 2px !important;
  font-size: 11px !important;
  line-height: 1.3 !important;
  position: relative !important;
}

#pdf-document .pdf-skills-container {
  margin-top: 4px !important;
  font-size: 11px !important;
}

#pdf-document .pdf-skills, #pdf-document .pdf-languages {
  margin: 0 0 4px !important;
  padding-bottom: 2px !important;
}

#pdf-document ul {
  margin: 0 !important;
  padding-left: 18px !important;
  list-style-type: disc !important;
}

#pdf-document li {
  padding-left: 5px !important;
  line-height: 1.3 !important;
}

#pdf-document li::marker {
  content: "• " !important;
}

@media print {
  #pdf-document {
    box-shadow: none;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  body {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }

  @page {
    size: A4;
    margin: 10mm;
  }
}

.pdf-experience-section, .pdf-experience-section h2 {
  background-color: #0000 !important;
}

#pdf-document .pdf-degree-honors-row {
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  margin-bottom: 2px !important;
  display: flex !important;
}

#pdf-document .pdf-degree {
  text-align: left !important;
  margin: 0 !important;
  font-size: 11px !important;
  font-weight: normal !important;
}

#pdf-document .pdf-honors {
  text-align: right !important;
  margin: 0 !important;
  font-size: 11px !important;
  font-style: italic !important;
}

#pdf-document .pdf-minor {
  text-align: left !important;
  width: 100% !important;
  margin-bottom: 2px !important;
  font-size: 11px !important;
  display: block !important;
}

#pdf-document .pdf-section:after, #pdf-document .pdf-section-title:after, #pdf-document .pdf-section:before, #pdf-document .pdf-section-title:before {
  content: none !important;
  display: none !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

/*# sourceMappingURL=index.baf8c985.css.map */
