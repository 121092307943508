/* PDF版简历的样式 - 调整字体和间距 */
#pdf-document {
  font-family: 'Arial', sans-serif !important;
  width: 210mm !important;
  height: 297mm !important;
  margin: 0 auto !important;
  padding: 12mm 15mm !important; /* 减小边距：从20mm 25mm减小到12mm 15mm */
  background-color: white !important;
  color: #333 !important;
  line-height: 1.2 !important;
  box-sizing: border-box !important;
}

#pdf-document * {
  background-color: transparent !important;
  font-family: 'Arial', sans-serif !important;
  margin: 0 !important;
  padding: 0 !important;
}

#pdf-document .pdf-header {
  text-align: center !important;
  margin-bottom: 10px !important; /* 标准间距 */
}

#pdf-document .pdf-header-row {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  width: 100% !important;
}

#pdf-document .pdf-name {
  font-size: 24px !important;
  font-weight: bold !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: left !important;
  flex: 1 !important;
}

#pdf-document .pdf-contact-info {
  text-align: right !important;
  flex: 1 !important;
}

#pdf-document .pdf-contact-item {
  font-size: 11px !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1.3 !important;
  text-align: right !important;
}

#pdf-document .pdf-divider {
  border: none !important;
  height: 1px !important;
  background-color: #999 !important;
  margin: 8px 0 !important;
  box-shadow: none !important;
}

#pdf-document .pdf-section {
  margin-bottom: 12px !important; /* 标准section间距 */
}

#pdf-document .pdf-section-title {
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0 0 8px 0 !important; /* 标准间距 */
  color: #333 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
  border-bottom: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
}

#pdf-document .pdf-education-entry,
#pdf-document .pdf-experience-entry,
#pdf-document .pdf-project-entry {
  margin-bottom: 10px !important; /* 标准条目间距 */
}

#pdf-document .pdf-entry-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  margin-bottom: 4px !important; /* 标准间距 */
}

#pdf-document .pdf-institution,
#pdf-document .pdf-job-title,
#pdf-document .pdf-project-title {
  font-size: 12px !important;
  font-weight: bold !important;
  margin: 0 !important;
}

#pdf-document .pdf-entry-date {
  text-align: right !important;
}

#pdf-document .pdf-date {
  font-size: 11px !important;
  font-style: italic !important;
  white-space: nowrap !important;
}

#pdf-document .pdf-entry-details {
  margin: 0 !important;
}

#pdf-document .pdf-degree,
#pdf-document .pdf-minor,
#pdf-document .pdf-honors,
#pdf-document .pdf-tech-stack {
  font-size: 11px !important;
  margin-bottom: 3px !important; /* 标准间距 */
}

#pdf-document .pdf-experience-details,
#pdf-document .pdf-project-details {
  padding-left: 18px !important; /* 标准列表缩进 */
  margin: 4px 0 0 0 !important; /* 标准间距 */
}

#pdf-document .pdf-detail-item {
  font-size: 11px !important;
  margin-bottom: 4px !important; /* 标准列表项间距 */
  padding-bottom: 2px !important;
  line-height: 1.3 !important; /* 标准行高 */
  position: relative !important;
}

#pdf-document .pdf-skills-container {
  font-size: 11px !important;
  margin-top: 4px !important; /* 标准间距 */
}

#pdf-document .pdf-skills,
#pdf-document .pdf-languages {
  margin: 0 0 4px 0 !important; /* 标准间距 */
  padding-bottom: 2px !important;
}

/* 确保列表样式正确，并在bullet point后添加空格 */
#pdf-document ul {
  list-style-type: disc !important;
  padding-left: 18px !important; /* 标准列表缩进 */
  margin: 0 !important;
}

#pdf-document li {
  line-height: 1.3 !important; /* 标准行高 */
  padding-left: 5px !important; /* 确保bullet point和文本之间有空格 */
}

/* 自定义bullet point样式，确保有空格 */
#pdf-document li::marker {
  content: "• " !important; /* 添加空格在bullet point后面 */
}

/* 打印样式优化 */
@media print {
  #pdf-document {
    padding: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
  
  body {
    margin: 0;
    padding: 0;
    background-color: white;
  }
  
  @page {
    size: A4;
    margin: 10mm; /* 减小打印边距 */
  }
}

/* 特别针对Professional Experience部分 */
.pdf-experience-section {
  background-color: transparent !important;
}

.pdf-experience-section h2 {
  background-color: transparent !important;
}

/* 添加专业和荣誉在同一行的样式 */
#pdf-document .pdf-degree-honors-row {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  margin-bottom: 2px !important;
}

#pdf-document .pdf-degree {
  font-size: 11px !important;
  text-align: left !important;
  font-weight: normal !important;
  margin: 0 !important;
}

#pdf-document .pdf-honors {
  font-size: 11px !important;
  text-align: right !important;
  font-style: italic !important;
  margin: 0 !important;
}

#pdf-document .pdf-minor {
  font-size: 11px !important;
  margin-bottom: 2px !important;
  text-align: left !important; /* 确保左对齐 */
  display: block !important; /* 确保是块级元素 */
  width: 100% !important; /* 确保占满整行 */
}

/* 禁用任何可能导致额外线条的样式 */
#pdf-document .pdf-section::after,
#pdf-document .pdf-section-title::after,
#pdf-document .pdf-section::before,
#pdf-document .pdf-section-title::before {
  display: none !important;
  content: none !important;
} 