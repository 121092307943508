/* Apple-inspired minimalist style for Resume page */
:root {
    --primary-color: #000;
    --secondary-color: #86868b;
    --accent-color: #0071e3;
    --background-color: #fff;
    --section-background: #f5f5f7;
    --border-color: #d2d2d7;
    --text-color: #1d1d1f;
    --spacing-unit: 8px;
    --secondary-font-size: 12px; /* 统一次要信息的字体大小 */
    --detail-font-size: 13px; /* 详情内容的字体大小 */
    --section-spacing: 32px; /* 统一section间距 */
    --item-spacing: 24px; /* 统一项目间距 */
}

.resume-page {
    font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.5; /* 增加行高，使文字更加通透 */
    color: var(--text-color);
    background-color: var(--background-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 800px; /* 减小最大宽度，适合单列布局 */
    margin: 0 auto; /* 居中显示 */
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); /* 添加阴影效果，使页面看起来像一张纸 */
    border-radius: 8px; /* 圆角边框 */
    overflow: hidden; /* 确保内容不会溢出边框 */
    font-size: 14px; /* 进一步减小基础字体大小 */
    letter-spacing: 0.01em; /* 增加字母间距，提高可读性 */
}

.container {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 calc(var(--spacing-unit) * 3);
    text-align: left; /* 确保容器内的文字左对齐 */
}

/* Typography */
.resume-page h1, 
.resume-page h2, 
.resume-page h3, 
.resume-page h4, 
.resume-page h5, 
.resume-page h6 {
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left; /* 确保所有标题左对齐 */
    margin-top: 0; /* 减少顶部边距 */
    color: var(--text-color); /* 恢复苹果风格的文字颜色 */
    line-height: 1.3; /* 统一标题行高 */
}

.resume-page h1 {
    font-size: 32px; /* 进一步减小h1字体大小 */
    margin-bottom: calc(var(--spacing-unit) * 1.5);
}

.resume-page h2 {
    font-size: 20px; /* 进一步减小h2字体大小 */
    margin-bottom: calc(var(--spacing-unit) * 2); /* 增加底部边距 */
    border-bottom: 1px solid var(--border-color);
    padding-bottom: calc(var(--spacing-unit) * 0.75); /* 增加底部内边距 */
}

.resume-page h3 {
    font-size: 16px; /* 进一步减小h3字体大小 */
    margin-bottom: calc(var(--spacing-unit) * 0.75); /* 调整底部边距 */
}

.resume-page h4 {
    font-size: 14px; /* 调整h4字体大小 */
    margin-bottom: calc(var(--spacing-unit) * 1); /* 调整底部边距 */
    font-weight: 500; /* 稍微减轻字重 */
}

.resume-page p {
    margin-bottom: calc(var(--spacing-unit) * 1); /* 增加段落间距 */
    text-align: left; /* 确保段落左对齐 */
    font-size: 14px; /* 进一步减小段落字体大小 */
    line-height: 1.5; /* 统一段落行高 */
}

.resume-page a {
    color: var(--accent-color);
    text-decoration: none;
    transition: color 0.3s ease;
}

.resume-page a:hover {
    text-decoration: underline;
}

.resume-page ul {
    list-style-position: outside; /* 改为外部列表样式，更专业 */
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整列表底部边距 */
    text-align: left; /* 确保列表左对齐 */
    padding-left: 20px; /* 添加适当的左内边距 */
}

.resume-page li {
    margin-bottom: calc(var(--spacing-unit) * 0.75); /* 增加列表项间距 */
    text-align: left; /* 确保列表项左对齐 */
    font-size: var(--detail-font-size); /* 统一列表项字体大小 */
    line-height: 1.5; /* 调整行高 */
}

/* Header & Navigation */
.resume-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 1000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.resume-header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    padding: 0 calc(var(--spacing-unit) * 3);
    max-width: 800px; /* 与页面宽度一致 */
    margin: 0 auto;
}

.resume-header .logo {
    font-weight: 600;
    font-size: 16px; /* 进一步减小logo字体大小 */
}

.resume-header nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding-left: 0; /* 移除导航列表的左内边距 */
}

.resume-header nav li {
    margin: 0 calc(var(--spacing-unit) * 2);
    margin-bottom: 0;
}

.resume-header nav a {
    color: var(--text-color);
    font-size: 12px; /* 进一步减小导航链接字体大小 */
    font-weight: 400;
    transition: color 0.3s ease;
}

.resume-header nav a:hover {
    color: var(--accent-color);
    text-decoration: none;
}

/* Hero Section */
.resume-page .hero {
    height: 25vh; /* 进一步减小hero高度 */
    min-height: 180px; /* 减小最小高度 */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; /* 保持hero部分居中对齐 */
    padding-top: 44px;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); /* 添加渐变背景 */
}

.resume-page .hero h1,
.resume-page .hero h2 {
    text-align: center; /* 保持hero部分的标题居中对齐 */
}

.resume-page .hero h2 {
    color: var(--secondary-color);
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 1.5);
    border-bottom: none; /* 移除标题下划线 */
    font-size: 18px; /* 进一步调整hero中h2的大小 */
}

.resume-page .hero p {
    max-width: 600px;
    margin: 0 auto;
    font-size: 15px; /* 进一步调整hero中段落的大小 */
    color: var(--secondary-color);
    text-align: center; /* 保持hero部分的段落居中对齐 */
}

/* Sections */
.resume-page section {
    padding: var(--section-spacing) 0; /* 使用统一的section间距 */
    border-bottom: 1px solid var(--border-color);
    text-align: left; /* 确保所有section左对齐 */
}

.resume-page section:last-of-type {
    border-bottom: none;
}

.resume-page section:nth-child(even) {
    background-color: var(--section-background);
}

/* About Section */
.about-content {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit) * 1.5); /* 减小间距 */
    text-align: left; /* 确保about内容左对齐 */
}

.about-text {
    flex: 1;
}

.about-text p {
    font-size: 14px; /* 进一步调整About Me部分的字体大小 */
    line-height: 1.5;
}

.skill-tags {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--spacing-unit) * 0.6); /* 减小技能标签间距 */
    margin-bottom: calc(var(--spacing-unit) * 1.25); /* 减小底部边距 */
    text-align: left; /* 确保技能标签左对齐 */
}

.skill-tags span {
    background-color: var(--section-background);
    padding: calc(var(--spacing-unit) * 0.3) calc(var(--spacing-unit) * 1); /* 减小内边距 */
    border-radius: 14px;
    font-size: 11px; /* 进一步减小技能标签字体大小 */
    display: inline-block;
    border: 1px solid var(--border-color);
}

/* Experience Section */
.experience-item, .education-item, .project-item {
    margin-bottom: var(--item-spacing); /* 使用统一的项目间距 */
    padding-bottom: var(--item-spacing); /* 使用统一的项目内边距 */
    border-bottom: 1px solid var(--border-color);
    text-align: left; /* 确保经验项目左对齐 */
}

.experience-item:last-child, .education-item:last-child, .project-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.experience-header, .education-header, .project-header {
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整头部边距 */
    text-align: left; /* 确保标题左对齐 */
}

/* 公司信息行样式 */
.company-info-row {
    position: relative;
    margin-bottom: calc(var(--spacing-unit) * 0.75); /* 调整底部边距 */
}

.company {
    display: inline-block;
    color: var(--secondary-color);
    font-size: var(--secondary-font-size);
    text-align: left;
}

/* 修改Experience部分的日期和地点样式，与Education部分一致 */
.period-location {
    font-size: var(--secondary-font-size);
    color: var(--secondary-color);
    margin-left: auto;
    white-space: nowrap;
    float: right;
    padding-left: calc(var(--spacing-unit) * 2);
    text-align: right;
}

.period-location .period,
.period-location .location {
    display: inline;
    margin-bottom: 0;
}

/* 教育信息行样式 */
.education-info-row {
    position: relative;
    margin-bottom: calc(var(--spacing-unit) * 0.4);
}

.degree-info {
    width: 100%;
    text-align: left;
}

.degree-info p {
    margin-bottom: calc(var(--spacing-unit) * 0.4); /* 统一底部边距 */
    position: relative; /* 添加相对定位，便于内部元素定位 */
}

.experience-details, .education-details, .project-details {
    margin-top: calc(var(--spacing-unit) * 1.5); /* 调整顶部边距 */
    text-align: left; /* 确保详情左对齐 */
}

/* 项目技术信息样式优化 */
.project-tech {
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整底部边距 */
    text-align: left;
}

.project-tech p {
    margin-bottom: calc(var(--spacing-unit) * 0.5); /* 调整底部边距 */
    font-size: var(--secondary-font-size);
    color: var(--secondary-color);
    line-height: 1.5; /* 统一行高 */
}

.project-tech strong {
    font-weight: 500;
    color: var(--text-color);
}

/* 项目详情样式优化 */
.project-details {
    margin-top: calc(var(--spacing-unit) * 1.5); /* 调整顶部边距 */
    text-align: left;
}

.project-details h4 {
    font-size: 14px;
    margin-bottom: calc(var(--spacing-unit) * 1); /* 调整底部边距 */
    color: var(--text-color);
    font-weight: 500;
}

.project-details p {
    font-size: var(--detail-font-size);
    line-height: 1.5;
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整底部边距 */
}

.project-details ul {
    margin-top: calc(var(--spacing-unit) * 0.75); /* 调整顶部边距 */
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整底部边距 */
}

.project-details li {
    font-size: var(--detail-font-size);
    line-height: 1.5;
    margin-bottom: calc(var(--spacing-unit) * 0.75); /* 调整底部边距 */
}

/* 统一项目技术信息中的标题样式 */
.project-tech strong {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
}

/* 统一经验详情样式 */
.experience-details {
    margin-top: calc(var(--spacing-unit) * 1.5); /* 调整顶部边距 */
}

.experience-details li {
    font-size: var(--detail-font-size);
    line-height: 1.5;
    margin-bottom: calc(var(--spacing-unit) * 0.75); /* 调整底部边距 */
}

/* 教育详情样式 */
.education-details {
    margin-top: calc(var(--spacing-unit) * 1.5); /* 调整顶部边距 */
}

.education-details p {
    font-size: var(--detail-font-size);
    line-height: 1.5;
    margin-bottom: calc(var(--spacing-unit) * 0.5); /* 调整底部边距 */
}

/* Contact Section */
.contact {
    background-color: var(--section-background);
    padding: calc(var(--spacing-unit) * 2.5) 0; /* 减小内边距 */
    text-align: left; /* 将联系方式改为左对齐 */
}

.contact p {
    font-size: 13px; /* 进一步调整联系方式字体大小 */
    margin-bottom: calc(var(--spacing-unit) * 0.6); /* 减小底部边距 */
}

/* Footer */
.resume-page footer {
    background-color: var(--primary-color);
    color: white;
    padding: calc(var(--spacing-unit) * 1.5) 0; /* 减小内边距 */
    text-align: center; /* 保持footer居中对齐 */
    font-size: 11px; /* 进一步减小footer字体大小 */
}

.resume-page footer a {
    color: white;
}

.resume-page footer p {
    margin-bottom: calc(var(--spacing-unit) * 0.4); /* 减小底部边距 */
    text-align: center; /* 保持footer文字居中对齐 */
    font-size: 11px; /* 统一字体大小 */
}

/* Responsive Design */
@media (max-width: 768px) {
    .resume-page h1 {
        font-size: 26px; /* 移动端进一步减小h1字体 */
    }
    
    .resume-page h2 {
        font-size: 18px; /* 移动端进一步减小h2字体 */
    }
    
    .resume-page h3 {
        font-size: 15px; /* 移动端进一步减小h3字体 */
    }
    
    .resume-page .hero {
        height: auto;
        padding: calc(var(--spacing-unit) * 10) 0 calc(var(--spacing-unit) * 5); /* 减小内边距 */
    }
    
    .resume-header nav ul {
        display: none;
    }
    
    .period-location-inline, .period-location {
        float: none;
        display: block;
        margin-left: 0;
        margin-top: calc(var(--spacing-unit) * 0.4);
        padding-left: 0;
    }
    
    .degree, .company {
        display: block;
        margin-right: 0;
    }
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.resume-page section {
    animation: fadeIn 0.8s ease-out forwards;
}

.resume-page section:nth-child(2) {
    animation-delay: 0.1s;
}

.resume-page section:nth-child(3) {
    animation-delay: 0.2s;
}

.resume-page section:nth-child(4) {
    animation-delay: 0.3s;
}

.resume-page section:nth-child(5) {
    animation-delay: 0.4s;
}

.resume-page section:nth-child(6) {
    animation-delay: 0.5s;
}

/* Active navigation link */
.resume-header nav a.active {
    color: var(--accent-color);
}

/* Fade element */
.fade-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in {
    opacity: 1;
    transform: translateY(0);
}

/* 打印样式 */
@media print {
    .resume-page {
        box-shadow: none;
        max-width: 100%;
        font-size: 10pt; /* 打印时使用更小的字体 */
    }
    
    .resume-header {
        position: static;
        margin-bottom: 12px; /* 减小底部边距 */
    }
    
    .resume-page section {
        padding: 6px 0; /* 减小内边距 */
        page-break-inside: avoid;
    }
    
    .resume-page .hero {
        height: auto;
        padding: 12px 0; /* 减小内边距 */
    }

    .resume-page h1 {
        font-size: 16pt; /* 打印时调整h1大小 */
    }

    .resume-page h2 {
        font-size: 13pt; /* 打印时调整h2大小 */
    }

    .resume-page h3 {
        font-size: 11pt; /* 打印时调整h3大小 */
    }

    .resume-page p, .resume-page li {
        font-size: 9pt; /* 打印时调整正文大小 */
    }
}

/* 统一次要信息字体大小 */
.period, .location, .degree, .honors, .minor, .client, .company {
    color: var(--secondary-color);
    margin-bottom: calc(var(--spacing-unit) * 0.2); /* 减小底部边距 */
    font-size: var(--secondary-font-size); /* 统一字体大小 */
    font-weight: normal; /* 默认字重 */
}

/* 添加斜体样式 */
.period, .location {
    font-style: italic;
}

/* 学位和公司名称加粗 */
.degree, .company {
    font-weight: 500; /* 稍微加粗 */
}

/* 教育信息行内日期和地点样式 */
.period-location-inline {
    font-size: var(--secondary-font-size);
    color: var(--secondary-color);
    margin-left: auto; /* 使用auto使其右对齐 */
    white-space: nowrap;
    float: right; /* 添加浮动使其右对齐 */
    padding-left: calc(var(--spacing-unit) * 2); /* 添加左内边距，与内容保持距离 */
}

.period-location-inline .period,
.period-location-inline .location {
    font-style: italic;
    margin-bottom: 0;
}

.degree {
    display: inline-block; /* 使其与内联元素在同一行 */
    margin-right: calc(var(--spacing-unit) * 1);
}

.project-item {
    margin-bottom: var(--item-spacing); /* 使用统一的项目间距 */
    padding-bottom: var(--item-spacing); /* 使用统一的项目内边距 */
    border-bottom: 1px solid var(--border-color);
    text-align: left; /* 确保经验项目左对齐 */
}

.project-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.project-header {
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整头部边距 */
    text-align: left; /* 确保标题左对齐 */
}

.project-header h3 {
    font-size: 16px; /* 进一步减小h3字体大小 */
    margin-bottom: calc(var(--spacing-unit) * 0.75); /* 调整底部边距 */
}

.project-header .company-info-row {
    position: relative;
    margin-bottom: calc(var(--spacing-unit) * 0.75); /* 调整底部边距 */
}

.project-header .company-info-row p {
    margin-bottom: 0;
}

.project-header .company-info-row .company {
    display: inline-block;
    color: var(--secondary-color);
    font-size: var(--secondary-font-size);
    text-align: left;
}

.project-header .period-location {
    font-size: var(--secondary-font-size);
    color: var(--secondary-color);
    margin-left: auto;
    white-space: nowrap;
    float: right;
    padding-left: calc(var(--spacing-unit) * 2);
    text-align: right;
}

.project-header .period-location .period,
.project-header .period-location .location {
    display: inline;
    margin-bottom: 0;
}

.project-tech {
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整底部边距 */
    text-align: left;
}

.project-tech p {
    margin-bottom: calc(var(--spacing-unit) * 0.5); /* 调整底部边距 */
    font-size: var(--secondary-font-size);
    color: var(--secondary-color);
    line-height: 1.5; /* 统一行高 */
}

.project-tech strong {
    font-weight: 500;
    color: var(--text-color);
}

.project-details {
    margin-top: calc(var(--spacing-unit) * 1.5); /* 调整顶部边距 */
    text-align: left;
}

.project-details h4 {
    font-size: 14px;
    margin-bottom: calc(var(--spacing-unit) * 1); /* 调整底部边距 */
    color: var(--text-color);
    font-weight: 500;
}

.project-details p {
    font-size: var(--detail-font-size);
    line-height: 1.5;
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整底部边距 */
}

.project-details ul {
    margin-top: calc(var(--spacing-unit) * 0.75); /* 调整顶部边距 */
    margin-bottom: calc(var(--spacing-unit) * 1.5); /* 调整底部边距 */
}

.project-details li {
    font-size: var(--detail-font-size);
    line-height: 1.5;
    margin-bottom: calc(var(--spacing-unit) * 0.75); /* 调整底部边距 */
}

/* 更新语言切换按钮样式，使其与下载按钮样式一致 */
.language-toggle {
    background-color: transparent; /* 保持透明背景 */
    border: 1px solid #ccc; /* 保持当前边框 */
    border-radius: 4px;
    color: #333; /* 保持当前文字颜色 */
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px; /* 与下载按钮一致的margin */
    padding: 5px 10px; /* 与下载按钮一致的padding */
    transition: all 0.3s ease;
}

.language-toggle:hover {
    background-color: #f0f0f0; /* 保持当前hover背景色 */
    border-color: #999; /* 保持当前hover边框色 */
}

/* 确保两个按钮高度一致 */
.language-toggle, .download-button {
    height: 30px; /* 统一高度 */
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* 在移动设备上调整导航和按钮 */
@media (max-width: 768px) {
    .resume-header nav {
        flex-direction: column;
        align-items: center;
    }
    
    .resume-header nav ul {
        margin: 10px 0;
    }
    
    .nav-buttons {
        margin: 10px 0;
        display: flex;
        flex-direction: row; /* 在移动设备上保持水平排列 */
        justify-content: center;
    }
    
    .language-toggle, .download-button {
        margin: 0 5px; /* 在移动设备上调整左右margin */
    }
}

.nav-buttons {
    display: flex;
    align-items: center;
}

.download-button {
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    padding: 5px 10px;
    transition: all 0.3s ease;
}

.download-button:hover {
    background-color: #0056b3;
} 