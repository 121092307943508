/* Apple-inspired minimalist style for Home page */
:root {
    --primary-color: #000;
    --secondary-color: #86868b;
    --accent-color: #0071e3;
    --background-color: #fff;
    --section-background: #f5f5f7;
    --border-color: #d2d2d7;
    --text-color: #1d1d1f;
    --spacing-unit: 8px;
}

.home-page {
    font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.5;
    color: var(--text-color);
    background-color: var(--background-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.container {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 calc(var(--spacing-unit) * 3);
    width: 100%;
}

/* Header */
.home-header {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: calc(var(--spacing-unit) * 3) 0;
}

.home-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-logo {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.02em;
}

.home-nav {
    display: flex;
    gap: calc(var(--spacing-unit) * 3);
}

.home-nav a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
    padding: calc(var(--spacing-unit)) calc(var(--spacing-unit) * 2);
    border-radius: 20px;
    background-color: var(--section-background);
    border: 1px solid var(--border-color);
}

.home-nav a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
}

/* Main Content */
.home-main {
    flex: 1;
    padding: calc(var(--spacing-unit) * 10) 0;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    text-align: center;
}

.home-hero {
    max-width: 700px;
    margin: 0 auto;
}

.home-hero h1 {
    font-size: 48px;
    line-height: 1.1;
    margin-bottom: calc(var(--spacing-unit) * 3);
    letter-spacing: -0.02em;
}

.home-hero h2 {
    font-size: 24px;
    font-weight: 400;
    color: var(--secondary-color);
    margin-bottom: calc(var(--spacing-unit) * 6);
}

.home-features {
    display: flex;
    justify-content: center;
    gap: calc(var(--spacing-unit) * 4);
    margin-top: calc(var(--spacing-unit) * 8);
}

.home-feature {
    background-color: rgba(255, 255, 255, 0.8);
    padding: calc(var(--spacing-unit) * 4);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    max-width: 300px;
    text-align: left;
}

.home-feature h3 {
    font-size: 20px;
    margin-bottom: calc(var(--spacing-unit) * 2);
}

.home-feature p {
    color: var(--secondary-color);
    font-size: 16px;
}

/* Footer */
.home-footer {
    background-color: var(--primary-color);
    color: white;
    padding: calc(var(--spacing-unit) * 4) 0;
    text-align: center;
}

.home-footer p {
    margin-bottom: calc(var(--spacing-unit));
    font-size: 14px;
}

.home-footer a {
    color: white;
    text-decoration: none;
}

.home-footer a:hover {
    text-decoration: underline;
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.home-hero, .home-feature {
    animation: fadeIn 0.8s ease-out forwards;
}

.home-feature:nth-child(2) {
    animation-delay: 0.2s;
}

.home-feature:nth-child(3) {
    animation-delay: 0.4s;
}

/* Responsive Design */
@media (max-width: 768px) {
    .home-header-content {
        flex-direction: column;
        gap: calc(var(--spacing-unit) * 2);
    }
    
    .home-features {
        flex-direction: column;
        align-items: center;
    }
    
    .home-hero h1 {
        font-size: 36px;
    }
    
    .home-hero h2 {
        font-size: 20px;
    }
} 