:root {
  --primary-color: #0071e3;
  --secondary-color: #333;
  --background-color: #f5f5f7;
  --text-color: #333;
  --light-text: #86868b;
  --border-color: #d2d2d7;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.about-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: var(--spacing-lg);
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: var(--font-family);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.about-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--border-color);
  margin-bottom: var(--spacing-lg);
}

.about-logo {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
}

.about-nav {
  display: flex;
  gap: var(--spacing-md);
}

.about-nav a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.2s;
}

.about-nav a:hover {
  color: var(--primary-color);
}

.about-hero {
  text-align: center;
  padding: var(--spacing-xl) 0;
  background: linear-gradient(135deg, #f5f5f7 0%, #e5e5ea 100%);
  border-radius: 8px;
  margin-bottom: var(--spacing-xl);
}

.about-hero h1 {
  font-size: 48px;
  margin-bottom: var(--spacing-md);
  font-weight: 700;
}

.about-hero p {
  font-size: 20px;
  color: var(--light-text);
  max-width: 600px;
  margin: 0 auto;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.about-section {
  margin-bottom: var(--spacing-lg);
}

.about-section h2 {
  font-size: 28px;
  margin-bottom: var(--spacing-md);
  position: relative;
  padding-bottom: var(--spacing-sm);
}

.about-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: var(--primary-color);
}

.about-section p {
  line-height: 1.6;
  margin-bottom: var(--spacing-md);
}

.about-team {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.team-member {
  text-align: center;
}

.team-member img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: var(--spacing-md);
}

.team-member h3 {
  margin-bottom: var(--spacing-sm);
}

.team-member p {
  color: var(--light-text);
  font-size: 14px;
}

.about-footer {
  margin-top: auto;
  padding-top: var(--spacing-lg);
  border-top: 1px solid var(--border-color);
  text-align: center;
  color: var(--light-text);
  font-size: 14px;
}

.about-footer a {
  color: var(--primary-color);
  text-decoration: none;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-hero, .about-section {
  animation: fadeIn 0.8s ease-out forwards;
}

.about-section:nth-child(2) {
  animation-delay: 0.2s;
}

.about-section:nth-child(3) {
  animation-delay: 0.4s;
}

/* Responsive styles */
@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 36px;
  }
  
  .about-hero p {
    font-size: 18px;
  }
  
  .about-team {
    grid-template-columns: 1fr;
  }
} 